import * as yup from 'yup';

export const ChangePasswordValidation = yup.object().shape({
  oldPassword: yup.string().required('Old password is required'),
  newPassword: yup.string().required('New password is required'),
  confirmPassword: yup
    .string()
    .when('newPassword', {
      is: (newPassword) => newPassword && newPassword.length > 0,
      then: yup.string().required('Confirm Password is required'),
    })
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});
