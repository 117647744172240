import React from 'react';
import SliderComponent from '../Slider/Slider';
import Card from './Card';
import classes from './Card.module.css';

const UpcomingEventsContainer = ({ events }) => {
  const cards = events.length && events.map((data, i) => (!data.isCancelled ? <Card item={data} /> : null));
  const filteredCards = cards ? cards.filter(Boolean) : [];

  return filteredCards.length ? (
    <div className={classes.UpcomingEventsContainer}>
      <div className={classes.Title}>
        Upcoming <span className='PrimaryTextBold'> Events</span>
      </div>
      <SliderComponent comp={filteredCards} />
    </div>
  ) : null;
};

export default UpcomingEventsContainer;
