import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredError = 'This field is required';
const minError = (n) => `Must be atleast ${n} characters`;

export const VolunteerFormValidation = yup.object().shape({
  name: yup.string().required(requiredError).min(1, minError(3)).max(50),
  email: yup.string().email('Field must be a valid email').required(requiredError).max(100),
  phone: yup.string().required(requiredError).matches(phoneRegExp, 'Invalid contact number'),
  affiliation: yup.string().required(requiredError),
  eventType: yup.string().required(requiredError),
  proposedDate: yup.string(),
  eventDescription: yup.string().required(requiredError),
});

export const VolunteerFormInitialValues = {
  name: '',
  email: '',
  phone: '',
  affiliation: '',
  eventType: '',
  proposedDate: '',
  eventDescription: '',
};
