import classes from './SocialTexts.module.css';
import React from 'react';

const SocialTexts = () => {
  const styles = {
    container: {
      transform: 'rotate(90deg)',
      width: '18.95vw',
      fontSize: '1vw',
      color: 'white',
      display: 'flex',
      position: 'absolute',
      right: '-8vw',
      top: '31vw',
      gridColumnGap: '1vw',
    },
  };
  return (
    <div style={styles.container} className={classes.SocialText}>
      <a href='https://www.facebook.com/harvardclubsv/' rel='noreferrer' target='_blank'>
        Facebook
      </a>
      <a href='https://www.linkedin.com/company/harvard-club-of-silicon-valley' rel='noreferrer' target='_blank'>
        LinkedIn
      </a>
      <div></div>
    </div>
  );
};

export default SocialTexts;
