import React from 'react';
import { camelToSentence } from '../../utils/stringHelper';
import classes from './MembershipTag.module.css';

const MembershipTag = ({ status }) => {
  return (
    <div className={classes.Membership}>
      <div className={classes.MembershipContent}>
        <span className='medium'> Membership: </span>
        <span className={classes.Inactive}> &nbsp; {camelToSentence(status)}</span>
      </div>
    </div>
  );
};

export default MembershipTag;
