import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredError = 'This field is required';
const minError = (n) => `Must be atleast ${n} characters`;

export const SchoolFormValidation = yup.object().shape({
  firstName: yup.string().required(requiredError).min(1, minError(1)).max(25),
  lastName: yup.string().required(requiredError).min(1, minError(1)).max(25),
  city: yup.string().required(requiredError),
  state: yup.string().required(requiredError),
  address: yup.string().required(requiredError).min(10, minError(10)),
  zipcode: yup.string().required(requiredError),
  email: yup.string().email('Field must be a valid email').required(requiredError).max(100),
  homePhone: yup.string().required(requiredError).matches(phoneRegExp, 'Invalid contact number'),
  workPhone: yup.string().required(requiredError).matches(phoneRegExp, 'Invalid contact number'),
  undergradDegree: yup.string().required(requiredError),
  concentration: yup.string().required(requiredError),
  year: yup.string().required(requiredError),
  affiliation: yup.string().required(requiredError),
  gradDegree: yup.string(),
  discipline: yup.string(),
  gradYear: yup.string().nullable(),
  jobTitle: yup.string(),
  company: yup.string(),
  hobbies: yup.string(),
  yrsOfInterviewing: yup.number(),
  noOfInterviews: yup.number(),
});

export const SchoolFormInitialValues = {
  firstName: '',
  lastName: '',
  city: '',
  state: '',
  address: '',
  zipcode: '',
  email: '',
  homePhone: '',
  workPhone: '',
  undergradDegree: '',
  concentration: '',
  year: '',
  affiliation: '',
  gradDegree: '',
  discipline: '',
  gradYear: '',
  jobTitle: '',
  company: '',
  hobbies: '',
  yrsOfInterviewing: '',
  noOfInterviews: '',
};
