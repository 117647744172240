import './App.css';
import './calendar.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PublicLayout from './Layouts/PublicLayout';
import AuthLayout from './Layouts/AuthLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header/Header';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <AuthProvider>
      <div className='App'>
        <ToastContainer
          position='top-right'
          autoClose={2100}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />
        <Router>
          <Header />
          <Switch>
            <Route path='/auth' component={AuthLayout} />
            <Route path='/' component={PublicLayout} />
          </Switch>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
