import React, { useContext, useEffect, useState } from 'react';
import { Input, Password } from '../../components/Inputs/Input';
import classes from './Login.module.css';
import { Form, Formik } from 'formik';
import { LoginFormInitialValues, LoginFormValidation } from '../../validations/LoginFormValidation';
import { NavLink, useHistory } from 'react-router-dom';
import { AuthHandler, API } from '../../utils/FormSubmitHandler';
import { PATHS, REMOVE_USER, SET_USER, START_LOADER, STOP_LOADER } from '../../constants';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import { LoaderContext } from '../../context/LoaderContext';

const Login = () => {
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  useEffect(() => {
    if (state.isAuthorized) history.push(PATHS.HOME);
    // eslint-disable-next-line
  }, [state.isAuthorized]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    setDisableSubmit(true);
    startLoader('Login');
    AuthHandler(values, API.LOGIN)
      .then(({ data }) => {
        const payload = { membershipStatus: data.data.membershipStatus, isAuthorized: true, token: data.data.jwt, data: data.data };
        dispatch({ type: SET_USER, payload });
        setDisableSubmit(false);
        stopLoader('Login');
        history.push(PATHS.HOME);
      })
      .catch((err) => {
        setDisableSubmit(false);
        if (err.response && err.response.data === 'Cannot read property role of null') toast.error('User is not registered');
        else if (err.response && err.response.data) toast.error(err.response.data.data);
        stopLoader('Login');
        dispatch({ type: REMOVE_USER });
      });
  };
  return (
    <div className={classes.Login}>
      <div className={classes.LoginOverlay}></div>
      <div className={classes.LoginContainer}>
        <div className={classes.Title}>
          <p>
            Login <br /> Now
          </p>
        </div>
        <div className={classes.Fields}>
          <Formik initialValues={LoginFormInitialValues} validationSchema={LoginFormValidation} onSubmit={handleSubmit}>
            <Form>
              <div className={classes.FormContainer}>
                <div>
                  <Input name='email' label='Email' placeholder='Jessicajames@gmail.com' />
                </div>
                <div>
                  <Password name='password' label='password' />
                </div>
                <div className={classes.Footer}>
                  <NavLink to='/auth/forgetPassword' style={{ cursor: 'pointer' }}>
                    Forgot Password
                  </NavLink>
                </div>
                <div className={classes.FooterButtons}>
                  <div>
                    <button type='submit' disabled={disableSubmit} className=' btn-primary btn-brown'>
                      Log-in
                    </button>
                  </div>
                  <div>
                    <NavLink
                      to='/register'
                      style={{ cursor: 'pointer' }}
                      className={classes.Submit + window.screen.width >= 600 ? ' btn-primary' : ' btn-black'}
                    >
                      Register
                    </NavLink>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
