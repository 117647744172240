import * as yup from 'yup';

export const DonationFormValidation = yup.object().shape({
  amount: yup.number().required('Amount is required'),
  description: yup.string(),
});

export const DonationFormInitialValues = {
  amount: '',
  description: '',
};
