import { ButtonBack, ButtonNext, CarouselProvider, Dot, DotGroup, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { useEffect, useState } from 'react';

const SliderComponent = ({ comp }) => {
  const [visibleSlides, setVisibleSlides] = useState(window.screen.width >= 600 ? 3 : 1);

  const [width, setWidth] = useState(window.screen.width);

  const handleResize = (e) => {
    setWidth(document.documentElement.clientWidth);
    document.documentElement.clientWidth >= 600 ? setVisibleSlides(3) : setVisibleSlides(1);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const slides = comp.map((component, i) => <Slide index={i}>{component}</Slide>);

  const cardsArray = Array.from({ length: comp.length }, (_, i) => i + 1);
  let dots = [<Dot slide={1} key={1} />];

  cardsArray.forEach((i) => {
    if (i % 3 === 0) dots.push(<Dot slide={i + 1} key={i + 1} />);
  });

  if (cardsArray[cardsArray.length - 1] % 3 === 0) {
    dots.pop();
    // dots[dots.length - 1] = (
    //   <Dot
    //     slide={cardsArray[cardsArray.length - 1]}
    //     key={cardsArray.length - 1}
    //   />
    // );
  }
  return (
    <CarouselProvider
      naturalSlideWidth={73}
      naturalSlideHeight={70}
      totalSlides={comp.length}
      interval={2800}
      isPlaying={window.screen.width <= 600}
      visibleSlides={visibleSlides}
      step={visibleSlides}
    >
      <Slider>{slides}</Slider>
      {width >= 600 ? (
        <div className='c_dots'>{dots}</div>
      ) : (
        <div className='c_dots'>
          <DotGroup />
        </div>
      )}

      <ButtonBack></ButtonBack>
      <ButtonNext></ButtonNext>
    </CarouselProvider>
  );
};

export default SliderComponent;
