import classes from './Services.module.css';
import React, { useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import Amisha from '../../assets/Amisha.png';
import { RESUME_INFO, SCOPE_LIST } from '../../constants';
import VolunteerFooter from '../../components/VolunteerFooter/VolunteerFooter';
import Expand from 'react-expand-animated';

const Services = () => {
  const transitions = ['height', 'opacity', 'background'];
  const [expand, setExpand] = useState(false);
  return (
    <div>
      <BgWithText title='Summer Community Service Fellowship (SCSF)' image='bg2' />
      <div className={classes.Content}>
        <div className='LargeText'>
          HCSV Announces
          <span className='brown'>
            {' '}
            Public <br /> Service Fellowship
          </span>
        </div>
        <p>
        In 2020, the Harvard Club of Silicon Valley worked with the Harvard Alumni Association to pilot a new program, today known as the Summer Community Service Fellowship.  Every year, PBHA reviews and vets applicants, who are current students at the College, before sending them to the HCSV Board for final selection. The winners receive grants to support community service work in Northern California nonprofits.  HCSV pays for these fellowships with a combination of member dues, event fees and sponsorships.
        </p>
        {/* <div className={classes.FellowshipRequirements}>
          <div className={classes.Title}>Fellowship Requirements</div>
          <div>
            <div className={classes.ReqCard}>
              <div className={classes.BigTitle}>01</div>
              <div className={classes.Subtitle}>
                The student must be in good standing with the university and elected to not enroll in Harvard in the fall semester or spring
                semester.
              </div>
            </div>
            <div className={classes.ReqCard}>
              <div className={classes.BigTitle}>02</div>
              <div className={classes.Subtitle}>
                The student must be enrolled at in an internship at a non-profit with 501(c)(3) status for a minimum of 6 weeks.
              </div>
            </div>
            <div className={classes.ReqCard}>
              <div className={classes.BigTitle}>03</div>
              <div className={classes.Subtitle}>The organization must be located in Northern California.</div>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.Title}>How to Submit</div>
          <p className={classes.mediumMargin}>
            Please email resume and cover letter explaining the scope of the project or internship to Shaun Chaudhuri at
            <a
              target='_blank'
              rel='noreferrer'
              href='mailto:sdchaudhuri2393@gmail.com'
              style={{ cursor: 'pointer' }}
              className='brown underline'
            >
              {' '}
              sdchaudhuri2393@gmail.com
            </a>
            . Applications are reviewed on a rolling basis.
          </p>
        </div> */}
        <div className='border'></div>
        <div className={classes.Title}>Recipients of the HCSV Public Fellowship:</div>
        <div className={classes.ResumeBox}>
          <div className={classes.ResumeContainer}>
            <div className={classes.Fields}>
              {RESUME_INFO.map((item) => (
                <div className={classes.FieldContainer}>
                  <div className={classes.Property}>{item.field}</div>
                  <div className={classes.Value}>{item.value}</div>
                </div>
              ))}
            </div>
            <div>
              <img className={classes.GraduateImage} src={Amisha} alt='' />
              <div className={classes.ReadMore + ' btn-primary'} onClick={() => setExpand(!expand)}>
                {!expand ? 'Read More' : 'Read Less'}
              </div>
            </div>
          </div>
          <div className={classes.Expand}>
            <Expand open={expand} duration={1000} transitions={transitions}>
              {SCOPE_LIST.map((item, index) => (
                <div className={classes.SContainer}>
                  <div className={classes.SNo}>0{index + 1}</div>
                  <div className={classes.SContent}>{item}</div>
                </div>
              ))}
              <p className={classes.FooterText}></p>
            </Expand>
          </div>
          <div className={classes.ExpandMobile}>
            {SCOPE_LIST.map((item, index) => (
              <div className={classes.SContainer}>
                <div className={classes.SNo}>0{index + 1}</div>
                <div className={classes.SContent}>{item}</div>
              </div>
            ))}
            <p className={classes.FooterText}>We will be posting a recap of Amisha’s experience in January!</p>
          </div>
        </div>
        <div style={{ marginTop: '7vw' }}>
          <VolunteerFooter />
        </div>
      </div>
    </div>
  );
};

export default Services;
