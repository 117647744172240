import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { AFFILIATION, TOAST_ERR } from '../../constants';
import { FormSubmitHandler, API } from '../../utils/FormSubmitHandler';
import { VolunteerFormInitialValues, VolunteerFormValidation } from '../../validations/VolunteerFormValidation';
import { DropdownComponent, Input, PhoneInput } from '../Inputs/Input';
import classes from './VolunteerForm.module.css';

const VolunteerForm = () => {
  const [isDisabled, setDisabled] = useState(false);
  const recaptchaRef = React.createRef();

  const handleSubmit = async (values, props) => {
    recaptchaRef.current.reset();
    let tempToken = await recaptchaRef.current.executeAsync();
    setDisabled(true);
    if (tempToken) {
      FormSubmitHandler(values, tempToken, API.VOLUNTEER)
        .then((res) => {
          toast.success(res.data.message);
          props.resetForm(VolunteerFormInitialValues);
          setDisabled(false);
        })
        .catch((err) => {
          toast.error(TOAST_ERR);
          setDisabled(false);
        });
    } else {
      toast.error(TOAST_ERR);
      setDisabled(false);
    }
  };
  return (
    <div className={classes.VolunteerForm}>
      <div className='LargeText'>
        <span className='PrimaryTextBold'>Propose An</span>
        <br /> Activity
      </div>
      <Formik initialValues={VolunteerFormInitialValues} validationSchema={VolunteerFormValidation} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <div className={classes.FormContainer + ' volunteerForm'}>
              <div>
                <Input name='name' label='Name' placeholder='Jessica' required />
                <Input name='email' label='Email' placeholder='Jessicajames@gmail.com' required />
              </div>
              <div>
                <div className={classes.PhoneInput}>
                  <PhoneInput name='phone' label='Phone' required />
                </div>
                <DropdownComponent
                  name='affiliation'
                  label='affiliation'
                  values={AFFILIATION}
                  setFieldValue={props.setFieldValue}
                  setFieldTouched={props.setFieldTouched}
                  getFieldMeta={props.getFieldMeta}
                  required
                />
              </div>
              <div>
                <Input name='eventType' label='Event Type' required />
                <Input name='proposedDate' label='Proposed Date(s)' />
              </div>
              <div>
                <Input name='eventDescription' label='Event Description' required />
              </div>
            </div>
            <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey='6Lc8buIZAAAAAMIbARG0H5epoGpWoSaDdOPHXIC9' />
            <button type='submit' disabled={isDisabled} className={classes.Submit + ' btn-primary'}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VolunteerForm;
