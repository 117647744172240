import React from 'react';
import classes from './LatestEvents.module.css';
import moment from 'moment';

const Card = ({ data }) => {
  return data ? (
    <div className={classes.Card}>
      <div className={classes.CardContent}>
        <div className={classes.TextContainer}>
          <p className={classes.TextMain}>{data.title}</p>
          {data.location && <p className={classes.TextSub}>{data.location.name}</p>}
        </div>
        <div className={classes.DateContainer}>
          <div className={classes.Month}>{moment(data.startDate).format('MMM')}</div>
          <div className={classes.Day}>{moment(data.startDate).format('DD')}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Card;
