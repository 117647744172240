import React from 'react';
import Particles from 'react-particles-js';
import { useHistory } from 'react-router-dom';
import LatestEvents from '../LatestEvents/LatestEvents';
import SocialTexts from '../SocialTexts/SocialTexts';
import classes from './Welcome.module.css';

const Welcome = ({ events }) => {
  const history = useHistory();
  return (
    <div className={classes.Welcome}>
      <div className={classes.WelcomeOverlay}></div>
      <div className={classes.WelcomeContent}>
        <div className={classes.Particles}>
          <Particles
            width='49vw'
            params={{
              particles: {
                number: {
                  value: 90,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: '#e6e6e6',
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    sides: 6,
                  },
                  image: {
                    src: 'img/github.svg',
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.81,
                  random: false,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 16.03412060865523,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 170.5362145832825,
                    size_min: 1.6241544246026904,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: true,
                  distance: 128.27296486924183,
                  color: '#e6e6e6',
                  opacity: 0.81,
                  width: 2.0844356791251797,
                },
                move: {
                  enable: true,
                  speed: 3.206824121731046,
                  direction: 'none',
                  random: false,
                  straight: false,
                  out_mode: 'bounce',
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: {
                    enable: false,
                    mode: 'repulse',
                  },
                  onclick: {
                    enable: false,
                    mode: 'push',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 1400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            }}
          />
        </div>
        <div className={classes.TextContent}>
          <div className={classes.Title}>
            Welcome to{' '}
            <span className='brown bold'>
              Harvard <br /> club{' '}
            </span>
            of Silicon Valley.
          </div>
          <div className={classes.Subtitle}>
            The Harvard Club of Silicon Valley organizes professional, social, and community service events for 15,000+ Harvard alumni in
            the greater Silicon Valley area.
          </div>
          <div className='whiteButton' onClick={() => history.push('/aboutus')}>
            Explore
          </div>
        </div>
      </div>
      <div className={classes.LatestEvents}>
        <div className={classes.LatestEventsOverlay} />
        <LatestEvents events={events || []} />
      </div>
      <SocialTexts />
    </div>
  );
};

export default Welcome;
