import classes from './CancellationPolicy.module.css';
import React, { useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';

const PrivacyPolicy = () => {
  return (
    <div>
      <BgWithText title='Privacy Statement' image='bg2' />
      <div className={classes.Content}>
        <div className='LargeText'>
          Privacy <span className='brown'>Policy</span>
        </div>
        <p>This Privacy Policy discloses the privacy practices for the Harvard Club of Silicon Valley.</p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          What Information <br /> <span className='brown'>Do We Gather About You?</span>
        </div>
        <p>
          We collect information from you when you access harvardclubsv.org and when you subscribe to our email service. As a result of
          those actions, you might supply us with such information as your name, address, email, and phone number. You can choose not to
          provide certain information, but then you might not be able to take advantage of some of our features. Periodically, we may ask
          you to confirm the accuracy of your information to avoid disruption of services.
        </p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          What Do We Use Your
          <br /> <span className='brown'>Information For?</span>
        </div>
        <p>
          We use the information you provide us to communicate with you about events for which you register. The email address and/or mobile
          phone number you provide may be used to send you information, respond to inquiries, and/or other requests or questions.
        </p>
        <p>
          In addition, visitor activity is tracked using Google Analytics. Google Analytics is subject to the privacy policy of Google,
          Inc., which you can find on Google’s website. These data are used to optimize our website for our visitors and do not include
          personally identifiable information such as name, contact information, or credit card numbers.
        </p>
        <p>
          All personally identifiable information provided by alumni is strictly reserved for use by individual alumni and for official club
          business. Personal information is shared with third parties:
          <ol>
            <li>Provision of services to users.</li>
            <li>
              User information may be shared with third-party partners to the extent necessary to provide and improve web services or other
              communications to users.
            </li>
            <li>
              Any third parties who receive user information for this purpose are prohibited from using or sharing user information for any
              purpose other than providing services to users
            </li>
            <li>Example of such a service is a joint event with another Harvard Club.</li>
          </ol>
        </p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          Third-Party <span className='brown'>Cookies</span>
        </div>
        <p>
          Cookies may be set by an organization other than alumni.harvard.edu. These “third-party cookies” may, for example, originate from
          websites such as YouTube, Twitter, Facebook, Google Plus, or other social media services for which alumni.harvard.edu has
          implemented “plug-ins.” Since the cookie policies of these sites change over time, you should determine their policies by visiting
          the privacy policy pages of these sites directly. You may disable cookies through your individual browser options. If you would
          like to learn more about cookies, please refer to the following website:{' '}
          <a href='https://www.allaboutcookies.org' className='brown' target='_blank' rel='noreferrer'>
            allaboutcookies.org
          </a>
          .
        </p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          Links To <span className='brown'>Third-Party Websites</span>
        </div>
        <p>
          We may provide hyperlinks to third-party websites as a convenience to our users; alumni.harvard.edu does not control third-party
          websites and is not responsible for the content of any linked-to third-party websites or any hyperlink in a linked-to website. We
          are not responsible for the privacy practices or the content of third-party websites.
        </p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          Email <span className='brown'>Policy</span>
        </div>
        <p>
          HCSV sends you email communications to inform you of news, events, and information relevant to you and the Harvard alumni
          community. If you require assistance in opting out of emails, please contact us at{' '}
          <a href='mailto:admin@harvardclubsv.org' rel='noreferrer' className='brown' target='_blank'>
            admin@harvardclubsv.org
          </a>
          .
        </p>
        <p>
          If you send us email messages, you should be aware that information disclosed in email may not be secure or encrypted and thus may
          be available to others. We suggest that you exercise caution when deciding to disclose any personal or confidential information in
          email. We will use your email address to respond directly to your questions or comments.
        </p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          Information <span className='brown'>Protection</span>
        </div>
        <p>
          To prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of information we gather about you, we have
          taken commercially reasonable steps to protect the information. However, no method of transmission over the Internet or method of
          electronic storage is 100 percent secure.
        </p>
        <p>HCSV has taken reasonable precautions to secure the personal information available through the online community.</p>

        <div className='LargeText' style={{ marginTop: '4vw' }}>
          Stripe <span className='brown'>Payments</span>
        </div>
        <p>
          The Harvard Club of Silicon Valley facilitates site payments through{' '}
          <a href='http://stripe.com/' rel='noreferrer' className='brown' target='_blank'>
            Stripe
          </a>
          . Stripe is a payment platform that has been has been audited by a PCI-certified auditor and is certified to{' '}
          <a
            href='https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'
            rel='noreferrer'
            className='brown'
            target='_blank'
          >
            PCI Service Provider Level 1
          </a>{' '}
          - this is the most stringent level of certification available in the payments industry.
        </p>
        <p>
          Stripe is fully transparent when it collects data from you, and in the case of payments done through HCSV's website, will only
          collect information that is needed for payment transactions (ie: payment card number, CVC code and expiration date). You can read
          more about Stripe's privacy policy{' '}
          <a href='https://stripe.com/privacy' rel='noreferrer' className='brown' target='_blank'>
            here
          </a>{' '}
          and more about their security measures{' '}
          <a
            href='https://stripe.com/docs/security/stripe#:~:text=Stripe%20has%20been%20audited%20by,level%20of%20security%20at%20Stripe.'
            rel='noreferrer'
            className='brown'
            target='_blank'
          >
            here
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
