import classes from './AddCard.module.css';
import React, { useContext, useMemo, useState } from 'react';
import p1 from '../../assets/p1.png';
import p2 from '../../assets/p2.png';
import p3 from '../../assets/p3.png';
import p4 from '../../assets/p4.png';
import lock from '../../assets/lock.png';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { API, SubmitWithToken } from '../../utils/FormSubmitHandler';
import { AuthContext } from '../../context/AuthContext';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '3vw',
          color: '#424770',
          letterSpacing: '0.025em',
          lineHeight: '7vw',
          padding: '0 4vw',
          fontFamily: 'Poppins',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const AddCard = ({ setRefresh, setModalOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { state } = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    if (!stripe || !elements) {
      setDisabled(false);
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (error) {
      toast.error(error.message);
      setDisabled(false);
      console.log('[error]', error);
    } else {
      SubmitWithToken(
        {
          customer: state.data.customerId,
          paymentMethod: paymentMethod.id,
        },
        API.ATTACH_PAYMENT
      )
        .then((res) => {
          setRefresh(Math.random * 100);
          setDisabled(false);
          setModalOpen(false);
          toast.success('Card added successfully');
          console.log('[PaymentMethod]', res);
        })
        .catch((err) => {
          setDisabled(false);
          if (err.response && err.response.data) toast.error(err.response.data.data);
          console.log('[error]', err);
        });
    }
  };
  return (
    <div className={classes.Modal}>
      <div></div>
      <div className={classes.Title}>Add New Card</div>
      <div>
        <div className={classes.Container}>
          <form>
            <div>
              <div>
                <p style={{ position: 'relative' }}>
                  <CardNumberElement className={classes.RadioContainer + ' MyCardElement'} options={options} />
                  <div className={classes.ImagesContainer}>
                    <img src={p1} alt='' />
                    <img src={p2} alt='' />
                    <img src={p3} alt='' />
                    <img src={p4} alt='' />
                  </div>
                </p>
              </div>
              <div className={classes.CardContainer}>
                <div className={classes.CardNo}>
                  <CardCvcElement className={classes.RadioContainer + ' MyCardElement'} options={options} />
                  <img src={lock} alt='' />
                </div>
                <div className={classes.CardNo}>
                  <CardExpiryElement className={classes.RadioContainer + ' MyCardElement'} options={options} />
                </div>
              </div>
            </div>
            <div className={classes.SubmitContainer}>
              <button onClick={handleSubmit} disabled={disabled} className='btn btn-primary'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
