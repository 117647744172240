import classes from './EventRegistration.module.css';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { PRICE_TYPES } from '../../constants';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-responsive-modal';
import ViewCards from '../ViewCards/ViewCards';
import { RegisterEventHandler } from '../../utils/FormSubmitHandler';
import { toast } from 'react-toastify';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
const EventRegistration = ({ event, setSelfOpen }) => {
  const { state } = useContext(AuthContext);
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
  const [priceDetails, setPriceDetails] = useState({
    patron: {},
    sponsor: {},
    recentGraduate: {},
    parent: {},
    inActive: {},
    individual: {},
    student: {},
  });

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [processedValues, setProcessedValues] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let sum = 0;
    let tickets = 0;
    Object.keys(priceDetails).forEach((key) => {
      if (priceDetails[key].cost !== undefined) {
        sum += priceDetails[key].cost;
        tickets += priceDetails[key].tickets;
      }
    });
    setTotalTickets(tickets);
    setTotalPrice(sum);
  }, [priceDetails]);

  const resetValues = () => {
    setPriceDetails({
      patron: {},
      sponsor: {},
      recentGraduate: {},
      parent: {},
      inActive: {},
      individual: {},
      student: {},
    });
    setTotalPrice(0);
    setTotalTickets(0);
    setPaymentMethod(null);
    setProcessedValues({});
  };

  const handleChange = (value, type, cost) => {
    let obj = {};
    obj[type] = {
      tickets: value,
      cost: value * cost,
    };
    setPriceDetails({ ...priceDetails, ...obj });
  };

  const handleSubmit = (values) => {
    let guestList = Object.values(values);
    let tickets = {};
    PRICE_TYPES.forEach((type) => {
      if (priceDetails[type.id].tickets) tickets[type.id] = priceDetails[type.id].tickets;
    });
   
    let processedValues = {
      amount: totalPrice,
      customer: state.data.customerId,
      guestList,
      tickets,
    };
    setProcessedValues(processedValues);
    if (totalTickets) {
      if (totalPrice === 0) {

        // RegisterEvent()

      } else {
        setModalOpen(true);
      }

    }
  };

  useEffect(() => {
    if (processedValues?.amount === 0 && processedValues?.guestList?.length > 0) {
      RegisterEvent()
    }
  }, [processedValues])


  const RegisterEvent = () => {
    startLoader('loading')
    setDisabled(true);
    let eventId = event.id;
    let values = { ...processedValues };
    values.paymentMethod = paymentMethod;
    RegisterEventHandler(eventId, values)
      .then(({ data }) => {
        toast.success(data.data);
        setDisabled(false);
        resetValues();
        setModalOpen(false);
        setSelfOpen(false);
        stopLoader('loading')
      })
      .catch((err) => {
        stopLoader('loading')
        console.log(err);
        setDisabled(false);
      });
  };
  const PRICES_TO_MAP = PRICE_TYPES.filter((type) => Boolean(event.cost[type.id]));
  return event ? (
    <div className={classes.EventRegistration}>
      <input type='text' className={classes.HiddenField} />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ errors, touched, isValidating }) => (
          <Form>
            <div className={classes.TopContainer}>
              <div className={classes.Prices}>
                <div className={classes.Title}>
                  <p>Prices</p>
                </div>
                <table className={classes.Table}>
                  {PRICES_TO_MAP.map((type, i) => {
                    if (event.cost[type.id] !== null) {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{type.name}</td>
                          <td>${event.cost[type.id]}</td>
                          <td>
                            <Dropdown
                              className='DropdownContainer'
                              options={[0, 1, 2, 3, 4, 5]}
                              value='0'
                              onChange={({ value }) => handleChange(value, type.id, parseInt(event.cost[type.id]))}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </table>
              </div>
              <div className={classes.OrderSummary}>
                <div className={classes.Title}>
                  <p>Order Summary</p>
                </div>
                <table className={classes.Table}>
                  {PRICE_TYPES.map((type, index) => {
                    if (event.cost[type.id] === null || !priceDetails[type.id].tickets) return null;
                    return (
                      <tr>
                        <td>
                          {priceDetails[type.id].tickets} x {type.name}
                        </td>
                        <td>${priceDetails[type.id].cost}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>
                      <div className={classes.CustomBorder + ' border'}></div>
                    </td>
                    <td>
                      <div className={classes.CustomBorder + ' border'} style={{ marginRight: '2vw' }}></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td className={classes.TotalPrice}>${totalPrice}</td>
                  </tr>
                </table>
                <div className={classes.CheckoutButton}>
                  {totalTickets + event.rsvpedCount > event.totalTickets ? (
                    <div className='error'>Only {event.totalTickets - event.rsvpedCount} can be booked</div>
                  ) : null}
                  <button type='submit' className='btn-primary' disabled={totalTickets + event.rsvpedCount > event.totalTickets}>
                    Checkout
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.BottomContainer}>
              <div className={classes.Title} style={{ marginLeft: '0' }}>
                Guests
              </div>
              <table>
                {[...Array(totalTickets).keys()].map((key) => (
                  <tr>
                    <td class={classes.RowNum}>{key + 1}</td>
                    <td>
                      <div>
                        <div className={classes.TableName}>Name</div>
                        <div className={classes.TableInput}>
                          <Field placeholder='Jessica' type='text' name={`${key}.name`} required />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div className={classes.TableName}>Email</div>
                        <div className={classes.TableInput}>
                          <Field placeholder='jessicajames@gmail.com' type='email' name={`${key}.email`} required />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </Form>
        )}
      </Formik>
      <div className='events-modal-container'>
        <Modal open={modalOpen} setModalOpen={setModalOpen} classNames={[classes.MyModal]} onClose={() => setModalOpen(false)}>
          <div className={classes.ModalContent}>
            <ViewCards setPaymentMethod={setPaymentMethod} />
            <button disabled={disabled} className='btn btn-primary' style={{ marginLeft: 'auto' }} onClick={RegisterEvent}>
              Confirm Registration
            </button>
          </div>
        </Modal>
      </div>
    </div>
  ) : null;
};

export default EventRegistration;
