import { useField } from 'formik';
import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Capitalize } from '../../utils/stringHelper';
import classes from './Input.module.css';

export const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label + ' label'}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div>
        <input type='text' {...field} {...props} required={false} />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const PhoneInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.PhoneInput}>
        <div className={classes.CountryCode}>
          +1
          {/* <i class="fa fa-caret-down" aria-hidden="true"></i> */}
        </div>
        <input type='text' placeholder='9876543120' {...field} {...props} required={false} />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const DropdownComponent = ({ label, ...props }) => {
  const meta = props.getFieldMeta(props.name);
  const handleDropdownChange = (e) => {
    props.setFieldValue(props.name, e.value);
  };
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.Dropdown}>
        <Dropdown
          value={props.defaultValue ? props.defaultValue.toString() : null}
          options={props.values}
          onChange={handleDropdownChange}
          placeholder='Select an option'
          onFocus={() => props.setFieldTouched(props.name, true)}
        />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const ClassDropdown = ({ label, ...props }) => {
  const meta = props.getFieldMeta(props.name);
  const handleDropdownChange = (e) => {
    props.setFieldValue(props.name, e.value);
  };
  let [defaultValue] = props.values.filter((value) => value.value === props.defaultValue);
  if (props.defaultValue === 0) defaultValue = '0';

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.Dropdown}>
        <Dropdown
          value={props.defaultValue || props.defaultValue === 0 ? defaultValue : null}
          options={props.values}
          onChange={handleDropdownChange}
          onFocus={() => props.setFieldTouched(props.name, true)}
        />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.TextArea}>
        <textarea {...field} {...props} required={false} />
        {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
      </div>
    </div>
  );
};

export const Password = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [type, setType] = useState('password');

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.PasswordContainer}>
        <input type={type} {...field} {...props} required={false} />
        {type === 'password' ? (
          <i class='fa fa-eye' aria-hidden='true' style={{ fontSize: '1vw', color: 'darkgrey' }} onClick={() => setType('text')} />
        ) : (
          <i
            class='fa fa-eye-slash'
            aria-hidden='true'
            style={{ fontSize: '1vw', color: 'darkgrey' }}
            onClick={() => setType('password')}
          />
        )}
      </div>
      {meta.touched && meta.error ? <div className='error-msg'>{meta.error}</div> : null}
    </div>
  );
};

const strongRegex = new RegExp('^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$');
/* ---STRONG REGEX-----
(?=.*[A-Z])               Ensure string has one uppercase letter.
(?=.*[!@#$&*])            Ensure string has one special case letter.
(?=.*[0-9].*[0-9])        Ensure string has two digits.
(?=.*[a-z].*[a-z].*[a-z]) Ensure string has three lowercase letters.
.{8}                      Ensure string is of length 8. 
*/

const mediumRegex = new RegExp('^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$');

export const PasswordWithStrength = ({ label, setPasswordStrength, ...props }) => {
  const [field] = useField(props);
  const [type, setType] = useState('password');

  const handlePasswordChanged = (e) => {
    const password = e.target.value;
    if (strongRegex.test(password)) setPasswordStrength('strong');
    else if (mediumRegex.test(password)) setPasswordStrength('medium');
    else setPasswordStrength('weak');
  };

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
      </div>
      <div className={classes.PasswordContainer}>
        <input type={type} {...field} {...props} required={false} onKeyUp={handlePasswordChanged} />
        {type === 'password' ? (
          <i class='fa fa-eye' aria-hidden='true' onClick={() => setType('text')} />
        ) : (
          <i class='fa fa-eye-slash' aria-hidden='true' onClick={() => setType('password')} />
        )}
      </div>
    </div>
  );
};
