import * as yup from 'yup';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredError = 'This field is required';
const minError = (n) => `Must be atleast ${n === 1 ? '1 character long' : n + ' characters long'}`;

export const RegisterFormValidation = yup.object().shape({
  firstName: yup.string().required(requiredError).min(1, minError(1)).max(50),
  middleName: yup.string().nullable().min(1, minError(1)).max(50),
  lastName: yup.string().required(requiredError).min(1, minError(1)).max(50),
  email: yup.string().email('Field must be a valid email').required(requiredError).max(100),
  lastNameAsStudent: yup.string().min(1, minError(1)).max(50),
  title: yup.string(),
  suffix: yup.string(),
  class: yup.string().required(requiredError),
  affiliation: yup.string(),
  addressLine1: yup.string().min(10, minError(10)),
  city: yup.string(),
  state: yup.string(),
  phone: yup.string(),
  zipcode: yup.string(),
  occupation: yup.string(),
  company: yup.string(),
  homePhone: yup.string(),
  mobilePhone: yup.string(),
  workPhone: yup.string(),
  fax: yup.string(),
  postEmail: yup.string().email(),
  undergradDegreeAndYear: yup.string(),
  gradDegreeAndYear: yup.string(),
  spouseName: yup.string(),
  spouseOccupation: yup.string(),
  hobbies: yup.string(),
  nameBadge: yup.string(),
});

export const RegisterFormInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  lastNameAsStudent: '',
  title: '',
  suffix: '',
  class: '',
  affiliation: '',
  addressLine1: '',
  city: '',
  state: '',
  phone: '',
  zipcode: '',
  occupation: '',
  company: '',
  homePhone: '',
  mobilePhone: '',
  workPhone: '',
  fax: '',
  postEmail: '',
  undergradDegreeAndYear: '',
  gradDegreeAndYear: '',
  spouseName: '',
  spouseOccupation: '',
  hobbies: '',
  nameBadge: '',
};
