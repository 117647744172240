import classes from './ChangePasswordModal.module.css';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { Form, Formik } from 'formik';
import { Input, Password, PasswordWithStrength } from '../Inputs/Input';
import { Capitalize } from '../../utils/stringHelper';
import { ChangePasswordValidation } from '../../validations/ChangePasswordValidation';
import { ChangePasswordHandler } from '../../utils/FormSubmitHandler';
import { get as lodashGet } from 'lodash';
import { toast } from 'react-toastify';

const ChangePasswordModal = ({ open, setOpen }) => {
  const [passwordStrength, setPasswordStrength] = useState('');
  let passwordClass = classes[passwordStrength];
  const handleSubmit = (values) => {
    ChangePasswordHandler(values)
      .then((res) => {
        toast.success('Password update success!');
        setOpen(false);
      })
      .catch((err) => {
        if (lodashGet(err, 'response.data.message') === 'Un_Authorized') {
          toast.error('Password update failed! Old password is wrong');
        }
      });
  };
  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)} classNames={{ root: 'login-modal' }}>
        <div className={classes.DonationModal + ' DonationModal'}>
          <div className={classes.Fields}>
            <Formik initialValues={{}} validationSchema={ChangePasswordValidation} onSubmit={handleSubmit}>
              <Form>
                <div className={classes.FormContainer}>
                  <div>
                    <Password name='oldPassword' label='Old Password' placeholder='' />
                  </div>
                  <div>
                    <PasswordWithStrength name='newPassword' label='New Password' setPasswordStrength={setPasswordStrength} />
                    {passwordStrength ? <div className={classes.Strength + ' ' + passwordClass}>{Capitalize(passwordStrength)}</div> : null}
                  </div>
                  <div>
                    <Password name='confirmPassword' label='Confirm Password' placeholder='' />
                  </div>
                  <div className={classes.ButtonContainer}>
                    <button className='btn-secondary'>Cancel</button>
                    <button className='btn-primary'>Change Password</button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
