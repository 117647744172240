import classes from './ChangeDefaultPayment.module.css';
import React, { useContext, useEffect, useState } from 'react';
import CardBg from '../../assets/card.png';
import { AuthContext } from '../../context/AuthContext';
import { API, SubmitWithToken, GetDefaultPayment, getMySubscriptions } from '../../utils/FormSubmitHandler';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { find as LodashFind } from 'lodash';
import { toast } from 'react-toastify';

const ChangeDefaultPayment = ({ setOpen, setDetectChange }) => {
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

  const { state } = useContext(AuthContext);
  const [cards, setCards] = useState([]);
  const [defaultPayment, setDefaultPayment] = useState('');
  const [refresh, setRefresh] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('');

  useEffect(() => {
    if (state.data && state.data.customerId) {
      startLoader('GetCards');
      SubmitWithToken({ customer: state.data.customerId }, API.GET_PAYMENT_METHODS)
        .then(({ data }) => {
          stopLoader('GetCards');
          if (setPaymentMethod && data?.data[0]?.id) setPaymentMethod(data.data[0].id);
          setCards(data.data);
        })
        .catch((err) => {
          stopLoader('GetCards');
          console.log(err);
        });

      GetDefaultPayment(state.data.customerId)
        .then(({ data }) => setDefaultPayment(data.data.defaultPaymentMethod))
        .catch((err) => console.log(err));
    }
  }, [refresh, state]);

  const filteredCards = cards.filter((card) => card.id !== defaultPayment);

  const handleChangeDefaultPayment = (pid) => {
    startLoader('changePayment');
    SubmitWithToken(
      {
        customer: state.data.customerId,
        paymentMethod,
      },
      API.SET_DEFAULT_PAYMENT
    )
      .then((res) => {
        stopLoader('changePayment');
        setOpen(false);
        setRefresh(Math.random() * 3);
        setDetectChange(Math.random() * 3);

        toast.success('Default payment method changed!');
      })
      .catch((err) => {
        stopLoader('changePayment');
        console.log(err);
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <p className='title-text-small brown' style={{ margin: '2em auto' }}>
            Saved Cards
          </p>
        </div>
      </div>
      <div className={classes.InputContainer + ' ' + classes.CardContainer}>
        {filteredCards.length ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {filteredCards.map((data, i) => {
              return (
                <div>
                  <p style={{ position: 'relative', paddingLeft: '28px' }}>
                    <input
                      type='radio'
                      id={data.id}
                      name='radio-button'
                      value={data.id}
                      defaultChecked={i === 0}
                      onChange={(e) => (setPaymentMethod ? setPaymentMethod(e.target.value) : null)}
                    />
                    <label htmlFor={data.id}>
                      <div className={classes.CardUIContainer}>
                        <div>
                          <img className={classes.CardImage} src={CardBg} alt='' />
                        </div>
                        <div className={classes.CardParent}>
                          <div className={classes.CardTop}>
                            <div>{data.card.brand.toUpperCase()}</div>
                            <div className={classes.WithCross}>
                              <div className={classes.CVV}>
                                <div className={classes.UItitle}>Valid Thru</div>
                                <div className={classes.UIContent}>
                                  {data.card.exp_month} / {data.card.exp_year}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classes.CardNum}>{`XXXX XXXX XXXX ${data.card.last4}`}</div>
                          <div className={classes.CardFooter}>
                            <div className={classes.CVV} style={{ color: 'white' }}>
                              {defaultPayment === data.id ? 'DEFAULT PAYMENT' : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <p style={{ textAlign: 'center', fontSize: '1vw' }}>No cards found!</p>
        )}{' '}
      </div>
      {filteredCards.length ? (
        <button className='btn btn-primary' style={{ marginLeft: 'auto' }} onClick={handleChangeDefaultPayment}>
          Confirm Change
        </button>
      ) : null}
    </div>
  );
};

export default ChangeDefaultPayment;
