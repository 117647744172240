import Axios from 'axios';

export const API = {
  REGISTER: 'register',
  LOGIN: 'login',
  LOGOUT: 'logout',
  FORGOT_PASSWORD: 'forgotPassword',
  VERIFY_OTP: 'verifyOTP',
  RESET_PASSWORD: 'resetPassword',
  SCHOOL: 'school',
  VOLUNTEER: 'volunteer',
  ATTACH_PAYMENT: 'user/paymentMethods/attach',
  DETACH_PAYMENT: 'user/paymentMethods/detach',
  GET_PAYMENT_METHODS: 'user/paymentMethods',
  SET_DEFAULT_PAYMENT: 'user/paymentMethods/default',
  SUBSCRIBE: 'user/subscribe',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const FormSubmitHandler = (values, token, type) => {
  Object.keys(values).forEach((key) => (values[key] == null || values[key] === '') && delete values[key]);
  values.role = 'subscriber';
  return Axios.post(`${BASE_URL + type}`, values, {
    params: {
      token,
    },
  });
};

export const SignupHandler = (values, type) => {
  Object.keys(values).forEach((key) => (values[key] == null || values[key] === '') && delete values[key]);
  values.role = 'subscriber';
  return Axios.post(`${BASE_URL + type}`, values);
};

export const VerifyUser = () => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.get(`${BASE_URL}whoami`, config);
};

export const AuthHandler = (values, type) => {
  values.role = 'subscriber';
  return Axios.post(`${BASE_URL + type}`, values);
};

export const SubmitWithToken = (values, type) => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  values.role = 'subscriber';
  return Axios.post(`${BASE_URL + type}`, values, config);
};

export const RegisterEventHandler = (id, values) => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  values.amount = values.amount * 100;
  return Axios.post(`${BASE_URL}event/${id}/register`, values, config);
};

export const getAllEvents = () => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      latest: true,
    },
  };
  return Axios.get(`${BASE_URL}event`, config);
};

export const UpdateUser = (values) => {
  // Object.keys(values).forEach((key) => (values[key] == null || values[key] === '') && delete values[key]);
  delete values['jwt'];
  delete values['id'];
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.patch(`${BASE_URL}user`, values, config);
};

export const DonationHandler = (values) => {
  Object.keys(values).forEach((key) => (values[key] == null || values[key] === '') && delete values[key]);
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.post(`${BASE_URL}user/donation`, values, config);
};

export const ListSubscriptions = () => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.get(`${BASE_URL}getAllSubscriptions`, config);
};

export const GetTransactions = (page, type, dates, userId) => {
  const limit = 10;
  const offset = page * limit;
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      offset,
      limit,
      type: type,
      from: dates.startDate,
      to: dates.endDate,
      userId,
    },
  };
  return Axios.get(`${BASE_URL}user/transactions`, config);
};

export const ListCoupons = () => {
  const limit = 100;
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      limit,
    },
  };
  return Axios.get(`${BASE_URL}subscription/coupon`, config);
};

export const ChangePasswordHandler = (values) => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.post(`${BASE_URL}user/changePassword`, values, config);
};

export const generateOtp = (payload) => {
  return Axios.post(`${BASE_URL}forgotPassword`, payload);
};

export const verifyOTP = (payload) => {
  return Axios.post(`${BASE_URL}verifyOTP`, payload);
};

export const resetPassword = (authToken, payload) => {
  const config = {
    params: {
      authToken,
    },
  };
  return Axios.post(`${BASE_URL}resetPassword`, payload, config);
};

export const getMySubscriptions = (cid) => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.get(`${BASE_URL}getSubscriptionsByCustomer/${cid}`, config);
};

export const cancelSubscription = (subscription) => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.post(`${BASE_URL}user/unsubscribe`, { subscription }, config);
};

export const GetDefaultPayment = (cid) => {
  const token = localStorage.getItem('harvard_token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return Axios.get(`${BASE_URL}getDefaultPaymentMethod/${cid}`, config);
};
