import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import classes from "./Accordion.module.css";
import Up from "../../assets/up.png";
import Down from "../../assets/down.png";

// Demo styles, see 'Styles' section below for some notes on use.
export default function AccordionComponent(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.AccordionComponent}>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        onChange={() => setOpen(!open)}
      >
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <span className="brown">{props.name.split(" ")[0]}</span>{" "}
                {props.name.split(" ")[1]}
              </div>
              <div>
                <img src={open ? Up : Down} alt="Acc" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {props.values.map((value, index) => (
              <p>
                {index + 1 + ". " + value.split("–")[0]} –{" "}
                <span className="brown">{value.split("–")[1]}</span>
              </p>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
