import classes from './Events.module.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import EventsImage from '../../assets/events.png';
import 'react-responsive-modal/styles.css';
import { getAllEvents } from '../../utils/FormSubmitHandler';
import ExpandableEventCard from '../../components/ExpandableEventCard/ExpandableEventCard';
import { AuthContext } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { get as lodashGet } from 'lodash';

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const id = lodashGet(history, 'location.state.id');

  useEffect(() => {
    if (id) {
      var elmnt = document.getElementById(id);
      if (elmnt) elmnt.scrollIntoView();
    }
  }, [eventData, id]);

  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    const { data } = await getAllEvents();
    setEventData(data.data.data);
  };

  return (
    <div className={classes.Events}>
      <BgWithText title='Events' image='bg2' />
      <div className={classes.Content}>
        <div className={classes.Container}>
          <div className={classes.HeadContainer}>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className={classes.TextContainer} style={{ flexDirection: 'column', display: 'flex' }}>
                <p>
                  To RSVP for an event, whether or not you are a Harvard alumni or HCSV member, you must first{' '}
                  <span
                    className='brown underline'
                    style={{ cursor: 'pointer' }}
                    onClick={() => (state.isAuthorized ? history.push('/') : history.push('/register'))}
                  >
                    Register as a User
                  </span>{' '}
                  on our site (registration is free). Once registered, you can then RSVP for events.
                </p>
                <p>
                  Reservations and cancellations to Harvard Club of Silicon Valley events are most easily made online by all registered
                  users of this website and HCSV mobile apps. Reservations and cancellations can also be placed by email at{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='mailto:admin@harvardclubsv.org'
                    style={{ cursor: 'pointer' }}
                    className='brown underline'
                  >
                    admin@harvardclubsv.org.
                  </a>
                </p>
                <p>
                  Please see event listings for restrictions, details, and rates, and familiarize yourself with the HCSV{' '}
                  <span className='brown underline' style={{ cursor: 'pointer' }} onClick={() => history.push('/cancellationpolicy')}>
                    Reservation/Cancellation Policy.
                  </span>
                </p>
              </div>
            </div>
            <div className={classes.ImageContainer}>
              <img className={classes.GraduateImage} src={EventsImage} alt='' />
            </div>
          </div>
        </div>
        {eventData.map((event) =>
          !event.isCancelled ? (
            <div id={event.id}>
              <ExpandableEventCard event={event} />{' '}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Events;
