import classes from './MembershipSummary.module.css';
import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { ListCoupons } from '../../utils/FormSubmitHandler';
import moment, { duration } from 'moment';
import { camelToSentence } from '../../utils/stringHelper';

const validateCouponCode = (couponCode, coupons) => {
  let error;
  const [selectedCoupon] = coupons.filter((coupon) => coupon.name === couponCode);
  const { name, times_redeemed, duration, max_redemptions, percent_off, redeem_by, valid } = selectedCoupon || {};

  if (['', null, undefined].includes(couponCode)) return (error = '');

  if (!selectedCoupon) error = 'Invalid Coupon';
  else if (!valid) error = 'Coupon is not valid';
  else if (times_redeemed > max_redemptions) error = 'Maximum Times Redeemed';
  else if (moment(redeem_by * 1000).diff(moment()) <= 0) error = 'Coupon Expired';

  return error;
};

const MembershipSummary = ({ nickname, amount, handleSummaryCheck }) => {
  const [coupons, setCoupons] = useState([]);
  console.log(coupons);
  useEffect(() => {
    ListCoupons()
      .then((res) => setCoupons(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const getTotal = (errors, values, type = '') => {
    const { coupon: error } = errors;
    const { coupon: value } = values;
    const [selectedCoupon] = coupons.filter((coupon) => coupon.name === value);
    if (type === 'getDiscountValue' && error) return 0;
    else if (type === 'getDiscountValue' && selectedCoupon) return selectedCoupon.percent_off;
    else if (type === 'getDiscountValue') return 0;

    if (!value || !selectedCoupon || error) return amount;
    else {
      let totalAmount = amount - (parseInt(selectedCoupon.percent_off) / 100) * amount;
      return totalAmount;
    }
  };

  const handleSubmit = (value) => {
    const [selectedCoupon] = coupons.filter((coupon) => coupon.name === value);
    if (selectedCoupon) handleSummaryCheck(selectedCoupon.id);
    else handleSummaryCheck('');
  };

  return (
    <Formik initialValues={{}} onSubmit={({ coupon }) => handleSubmit(coupon)}>
      {({ errors, touched, isValidating, values }) => (
        <Form>
          <div className={classes.OrderSummary}>
            <div className={classes.Title}>
              <p>Order Summary</p>
            </div>
            <table className={classes.Table}>
              <tr>
                <td>1 x {camelToSentence(nickname)}</td>
                <td>${amount}</td>
              </tr>
              {getTotal(errors, values, 'getDiscountValue') ? (
                <tr className={classes.Discount}>
                  <td>Discount</td>
                  <td>{getTotal(errors, values, 'getDiscountValue')}%</td>
                </tr>
              ) : null}
              <tr>
                <td>
                  <div className={classes.CustomBorder + ' border'}></div>
                </td>
                <td>
                  <div className={classes.CustomBorder + ' border'} style={{ marginRight: '2vw' }}></div>
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td className={classes.TotalPrice}>${getTotal(errors, values)}</td>
              </tr>
            </table>
            {nickname === 'recentGraduate' ? (
              <div>
                <div className={classes.CouponInput}>
                  <div className={classes.TableName}>Coupon Code (If Any)</div>
                  <div className={classes.TableInput}>
                    <Field placeholder='' type='text' name='coupon' validate={(value) => validateCouponCode(value, coupons)} />
                    {errors.coupon && touched.coupon && <div className={classes.Error}>{errors.coupon}</div>}
                  </div>
                </div>
              </div>
            ) : null}
            <div className={classes.CheckoutButton}>
              <button type='submit' className='btn-primary'>
                Checkout
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MembershipSummary;
