import classes from './ExpandableEventCard.module.css';
import React, { useContext, useState } from 'react';
import Expand from 'react-expand-animated';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import EventRegistration from '../EventRegistration/EventRegistration';
import Fallback from '../../assets/DefaultEvent.png';
import LoginModal from '../LoginModal/LoginModal';
import { AuthContext } from '../../context/AuthContext';
import MEDitor from '@uiw/react-md-editor';
import { isSameDates } from '../../utils/dateHelper';
import moment from 'moment';

const compareCosts = (c1, c2) => c1 === c2;

const ExpandableEventCard = ({ event }) => {
  const { state } = useContext(AuthContext);
  const transitions = ['height', 'opacity', 'background'];

  const [expand, setExpand] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loginModal, setLoginModal] = useState({ show: false });

  const [organizerInfo, showOrganizerInfo] = useState(false);

  return (
    <div>
      <div className={classes.ExpandableContainer}>
        <div className={classes.ExpandableContent}>
          <div className={classes.Header} onClick={() => setExpand(!expand)}>
            <img src={event.image || Fallback} alt='' />
            <div className={classes.Title}>
              <span className='brown'>{event.title}</span>
            </div>
            <div className={classes.Card}>
              <div className={classes.CardContent}>
                <div className={classes.FieldContainer}>
                  <div className={classes.Field}>Date :</div>
                  <div className={classes.Value}>
                    {isSameDates(event.startDate, event.endDate)
                      ? moment(event.startDate).format('dddd, MMMM Do')
                      : `${moment(event.startDate).format('MMMM Do')} - 
                    ${moment(event.endDate).format('MMMM Do')}`}
                  </div>
                </div>
                <div className={classes.FieldContainer}>
                  <div className={classes.Field}>Time :</div>
                  <div className={classes.Value}>
                    {moment(event.startDate).format('h:mm a')} - {moment(event.endDate).format('h:mm a')}
                  </div>
                </div>
                {event.eventInfo.type === 'webinar' || (event.location && event.location.name) ? (
                  <div className={classes.FieldContainer}>
                    <div className={classes.Field}>Place :</div>
                    <div className={classes.Value}>
                      {event.eventInfo.type !== 'webinar' ? event.location && event.location.name : 'Virtual'}
                    </div>
                  </div>
                ) : null}
                <div className={classes.FieldContainer}>
                  <div className={classes.Field}>Cost :</div>
                  <div className={classes.Value}>
                    {Math.min(...Object.values(event.cost)) === Math.max(...Object.values(event.cost))
                      ? `$${Math.min(...Object.values(event.cost))}`
                      : `$${Math.min(...Object.values(event.cost))} - $${Math.max(...Object.values(event.cost))}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Expand open={expand} duration={1000} transitions={transitions}>
            <div className={classes.EventContents} id='eventDesc'>
              <MEDitor.Markdown source={event.description} />
            </div>
          </Expand>
          <div className={classes.Footer}>
            <div className={classes.FooterButtons}>
              {!state.isAuthorized ? (
                <div
                  className='btn-primary'
                  onClick={() =>
                    setLoginModal({
                      show: true,
                      text: 'Please login to your Harvard Club of Silicon Valley to register for events',
                    })
                  }
                >
                  Register Here
                </div>
              ) : event.rsvpedCount >= event.totalTickets ? (
                <div className='btn-primary' onClick={() => setLoginModal({ show: true, text: 'Registration is full for this event!' })}>
                  Register Here
                </div>
              ) : !moment(event.rsvpDate).isBefore(moment()) ? (
                event.eventInfo.type !== 'thirdParty' ? (
                  <div className='btn-primary' onClick={() => setModalOpen(true)}>
                    Register Here
                  </div>
                ) : (
                  <a target='_blank' rel='noreferrer' href={event.eventInfo.url} className='btn-primary'>
                    Register Here
                  </a>
                )
              ) : (
                <div className='btn-primary' onClick={() => setLoginModal({ show: true, text: 'Registration for this event is closed!' })}>
                  Register Here
                </div>
              )}
              {event.organizer.enabled ? (
                <div className='btn-black' onClick={() => showOrganizerInfo(true)}>
                  Organizer Info
                </div>
              ) : null}
              {event.eventInfo.type !== 'webinar' && event.location.lat && (
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`https://www.google.com/maps/search/?api=1&query=${event.location.lat},${event.location.lng}`}
                  className='btn-black'
                >
                  Event Location
                </a>
              )}
              {/* {event.eventInfo.type === 'webinar' && event.eventInfo.url && (
                <a target='_blank' rel='noreferrer' href={event.eventInfo.url} className='btn-black'>
                  Webinar URL
                </a>
              )} */}
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
              {!expand ? (
                <div className={classes.ReadMore + ' btn-secondary'} onClick={() => setExpand(!expand)}>
                  Read More
                </div>
              ) : (
                <div className={classes.ReadMore + ' btn-secondary'} onClick={() => setExpand(!expand)}>
                  Read Less
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <EventRegistration event={event} setSelfOpen={setModalOpen} />
      </Modal>
      <Modal open={organizerInfo} onClose={() => showOrganizerInfo(false)}>
        <div className={classes.Event}>
          <div className={classes.Card}>
            <div className={classes.OrganizerText}>Organizer Info</div>
            <div className={classes.CardContent}>
              <div className={classes.FieldContainer}>
                <div className={classes.Field}>Name :</div>
                <div className={classes.Value}>{event.organizer.name}</div>
              </div>
              <div className={classes.FieldContainer}>
                <div className={classes.Field}>Email :</div>
                <div className={classes.Value}>{event.organizer.email} </div>
              </div>
              <div className={classes.FieldContainer}>
                <div className={classes.Field}>Phone :</div>
                <div className={classes.Value}>{event.organizer.phone} </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <LoginModal open={loginModal.show} setOpen={setLoginModal} {...loginModal} />
    </div>
  );
};

export default ExpandableEventCard;
