import React from "react";
import { Route, Switch } from "react-router-dom";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import Login from "../pages/Login/Login";

const AuthLayout = () => {
  return (
    <Switch>
      <Route path="/auth/login" exact component={Login} />
      <Route path="/auth/forgetPassword" exact component={ForgetPassword} />
    </Switch>
  );
};

export default AuthLayout;
