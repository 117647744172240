import classes from './DonationModal.module.css';
import React, { useContext, useState } from 'react';
import Modal from 'react-responsive-modal';
import { Form, Formik } from 'formik';
import { Input, TextArea } from '../Inputs/Input';
import ViewCards from '../ViewCards/ViewCards';
import { DonationHandler } from '../../utils/FormSubmitHandler';
import { toast } from 'react-toastify';
import { DonationFormInitialValues, DonationFormValidation } from '../../validations/DonationFormValidation';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';

const DonationModal = ({ open, setOpen, customer }) => {
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [values, setValues] = useState(null);

  const handleSubmit = (values, { setSubmitting }) => {
    setValues(values);
    setConfirmationModal(true);
  };

  const handleDonate = () => {
    setDisabled(true);
    startLoader('donationModal');
    let processedValues = { ...values, customer, paymentMethod };
    processedValues.amount = processedValues.amount * 100;
    DonationHandler(processedValues)
      .then(({ data }) => {
        toast.success(data.data);
        setConfirmationModal(false);
        stopLoader('donationModal');
        setOpen(false);
        setDisabled(false);
      })
      .catch((err) => {
        stopLoader('donationModal');
        console.log(err);
        setDisabled(false);
        setConfirmationModal(false);
      });
  };

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)} classNames={{ root: 'login-modal' }}>
        <div className={classes.DonationModal + ' DonationModal'}>
          <div className={classes.Fields}>
            <Formik initialValues={DonationFormInitialValues} validationSchema={DonationFormValidation} onSubmit={handleSubmit}>
              <Form>
                <div className={classes.FormContainer}>
                  <div>
                    <Input name='amount' label='Amount' placeholder='' required />
                  </div>
                  <div>
                    <TextArea name='description' label='Description' placeholder='' />
                  </div>
                  <div className={classes.ButtonContainer}>
                    <button className='btn-secondary'>Cancel</button>
                    <button className='btn-primary donation-button'>Donate</button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Modal>
      <div className='events-modal-container'>
        <Modal
          open={confirmationModal}
          setModalOpen={setConfirmationModal}
          classNames={[classes.MyModal]}
          onClose={() => setConfirmationModal(false)}
        >
          <div className={classes.ModalContent}>
            <ViewCards setPaymentMethod={setPaymentMethod} />
            <button disabled={disabled} className='btn btn-primary' style={{ marginLeft: 'auto' }} onClick={handleDonate}>
              Confirm Donate
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DonationModal;
