import React, { useContext, useState } from 'react';
import { PAYMENT_IMAGES } from '../../constants';
import { AuthContext } from '../../context/AuthContext';
import DonationModal from '../DonationModal/DonationModal';
import LoginModal from '../LoginModal/LoginModal';
import classes from './VolunteerFooter.module.css';

const VolunteerFooter = () => {
  const { state } = useContext(AuthContext);
  const [donationModal, setDonationModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const handleDonate = () => {
    if (state && state.data && state.data.customerId) setDonationModal(true);
    else setLoginModal(true);
  };

  return (
    <div className={classes.Footer}>
      <div className={classes.FooterContainer}>
        <div className={classes.DonationCard}>
          <div className={classes.ImagesContainer}>
            {PAYMENT_IMAGES.map((image) => (
              <img src={image} alt='' />
            ))}
          </div>
          <div className={classes.DonateButton} onClick={handleDonate}>
            Donate
          </div>
        </div>
        <div className={classes.OverlayContainer}>
          <div className={classes.OverlayText}>We thank you for your continued support.</div>
          <div className={classes.ThankyouText}>
            {' '}
            We <span className='yellow'>thank you</span> for your continued support.
          </div>
        </div>
      </div>
      <DonationModal open={donationModal} setOpen={setDonationModal} customer={state.isAuthorized ? state.data.customerId : null} />
      <LoginModal open={loginModal} isLogin setOpen={setLoginModal} />
    </div>
  );
};

export default VolunteerFooter;
