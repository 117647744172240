import * as yup from 'yup';

export const ForgetPasswordFormValidation = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  newPassword: yup.string(),
  confirmPassword: yup
    .string()
    .when('newPassword', {
      is: (newPassword) => newPassword && newPassword.length > 0,
      then: yup.string().required('Confirm Password is required'),
    })
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export const ForgetPasswordFormInitialValues = {
  email: '',
};
