import classes from './CancellationPolicy.module.css';
import React, { useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';

const CancellationPolicy = () => {
  return (
    <div>
      <BgWithText title='Cancellation Policy' image='bg2' />
      <div className={classes.Content}>
        <div className='LargeText'>
          Reservation <br /> <span className='brown'>& Cancellation Policy</span>
        </div>

        <p>
          To provide members and guests the utmost service and the best events possible, the Harvard Club of Silicon Valley will require you
          to register or RSVP to the events posted on the page.
        </p>

        <p>
          Please place reservations, and/or purchase tickets, on or before the recommended RSVP deadline so that we know you will be
          attending and so that we can inform you of any key details or updates regarding the events you are interested in.
        </p>

        <p>
          Paid tickets are also non-refundable and any member or guest who RSVPs, but fails to attend will still be responsible for the
          price of the ticket(s).
        </p>

        <p>Thank you for your support of the Harvard Club of Silicon Valley. We look forward to seeing you at more great events</p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
