import React from 'react';
import { useHistory } from 'react-router-dom';
import { ANNOUNCEMENTS } from '../../constants';
import classes from './AnnouncementCard.module.css';
const AnnouncementCard = () => {
  const history = useHistory();
  return ANNOUNCEMENTS.map((announcement) => (
    <div className={classes.AnnouncementCard}>
      <div className={classes.CardContent}>
        <div className={classes.Title + ' HeadingText'}>{announcement.title}</div>
        <div className={classes.content}>
          {announcement.contents.map((content) => (
            <p>{content}</p>
          ))}
        </div>
        <button
          className='btn-primary'
          onClick={() =>
            announcement.link ? history.push(announcement.link) : announcement.anchor ? window.open(announcement.anchor) : null
          }
        >
          {announcement.buttonText}
        </button>
      </div>
      <div className={classes.AnnouncementImage}>
        <img src={announcement.image} alt='' />
      </div>
    </div>
  ));
};

export default AnnouncementCard;
