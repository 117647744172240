import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { useEffect, useState } from 'react';

const SliderWithoutButtons = ({ comp }) => {
  const [visibleSlides, setVisibleSlides] = useState(window.screen.width >= 600 ? 2 : 1);

  const handleResize = (e) => {
    document.documentElement.clientWidth >= 600 ? setVisibleSlides(2) : setVisibleSlides(1);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const slides = comp.map((component, i) => <Slide index={i}>{component}</Slide>);
  return (
    <CarouselProvider
      naturalSlideWidth={70}
      naturalSlideHeight={55}
      totalSlides={comp.length}
      interval={4000}
      isPlaying={true}
      visibleSlides={visibleSlides}
      step={1}
    >
      <Slider>{slides}</Slider>
    </CarouselProvider>
  );
};

export default SliderWithoutButtons;
