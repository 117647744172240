import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredError = 'This field is required';
const minError = (n) => `Must be atleast ${n === 1 ? '1 character long' : n + ' characters long'}`;

export const ProfileValidation = yup.object().shape({
  firstName: yup.string().required(requiredError).min(1, minError(1)).max(25),
  middleName: yup.string().nullable().min(1, minError(1)).max(25),
  lastName: yup.string().required(requiredError).min(1, minError(1)).max(25),
  email: yup.string().email('Field must be a valid email').required(requiredError).max(100),
  newPassword: yup.string(),
  oldPassword: yup.string().when('newPassword', {
    is: (newPassword) => newPassword && newPassword.length > 0,
    then: yup.string().required(),
  }),
  confirmPassword: yup
    .string()
    .when('newPassword', {
      is: (newPassword) => newPassword && newPassword.length > 0,
      then: yup.string().required(),
    })
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});
