import React from 'react';
import SliderWithoutButtons from '../SliderWithoutButtons/SliderWithoutButtons';
import Card from './Card';
import classes from './LatestEvents.module.css';

const LatestEvents = ({ events }) => {
  const cards = events.map((data, i) => (!data.isCancelled ? <Card data={data} /> : null));
  const filteredCards = cards ? cards.filter(Boolean) : [];
  return filteredCards.length ? (
    <div className={classes.EventsContainer}>
      <div className={classes.Title}>
        <p>Our Latest Events</p>
      </div>
      <div className={classes.Cards + ' withoutButtons'}>
        <SliderWithoutButtons comp={filteredCards} />
      </div>
    </div>
  ) : null;
};

export default LatestEvents;
