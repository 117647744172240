import classes from "./InfoCard.module.css";
import LinkedIn from './../../assets/linkedinIcon.png'
import React from "react";

const InfoCard = ({ image, title, subTitle, link }) => {
  return (

    <div className={classes.Card}>
      <div className="text-center">
        <img src={image} alt="" />
      </div>
      <p className={classes.CardTitle}>{title}</p>
      <p className={classes.SubTitle}>{subTitle}</p>
      <a href={link} target="_blank" rel="noreferrer">
        <div className="text-center">
          <img className={classes.LinkedinIcon} src={LinkedIn} alt="linkedinIcon" />
        </div>
      </a>
    </div >
  );
};

export default InfoCard;
