import React, { useEffect, useState } from 'react';
import classes from './BgWithText.module.css';
import bg1 from '../../assets/bg1.png';
import bg2 from '../../assets/bg2.png';
import bg3 from '../../assets/bg3.png';
import bg1m from '../../assets/bg1m.png';
import bg2m from '../../assets/bg2m.png';

const BgWithText = ({ title, image = null }) => {
  const [mobile, setMobile] = useState(document.documentElement.clientWidth <= 600);
  const handleResize = (e) => {
    setMobile(document.documentElement.clientWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let BgImage = image === 'bg1' ? bg1 : image === 'bg2' ? bg2 : bg3;
  if (mobile) BgImage = image === 'bg1' ? bg1m : bg2m;
  return (
    <div className={classes.BgWithText} style={{ background: `url(${BgImage})` }}>
      <div className={classes.Title}>{title}</div>
      <div className={classes.OverlayText}>{title}</div>
    </div>
  );
};

export default BgWithText;
