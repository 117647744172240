import classes from './ViewCards.module.css';
import React, { useContext, useEffect, useState } from 'react';
import CardBg from '../../assets/card.png';
import AddCardModal from '../AddCardModal/AddCardModal';
import { AuthContext } from '../../context/AuthContext';
import { API, SubmitWithToken, GetDefaultPayment, getMySubscriptions } from '../../utils/FormSubmitHandler';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { find as LodashFind } from 'lodash';
import { toast } from 'react-toastify';

const ViewCards = ({ setPaymentMethod, profilePage = false, detectChange = 1 }) => {
  const { dispatch: loaderDispatch } = useContext(LoaderContext);
  const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
  const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
  const [subscriptions, setSubsriptions] = useState([]);
  const { state } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [defaultPayment, setDefaultPayment] = useState('');
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    if (state.data && state.data.customerId) {
      startLoader('GetCards');
      SubmitWithToken({ customer: state.data.customerId }, API.GET_PAYMENT_METHODS)
        .then(({ data }) => {
          stopLoader('GetCards');
          if (setPaymentMethod && data && data.data[0] && data.data[0].id) setPaymentMethod(data.data[0].id);
          setCards(data.data);
        })
        .catch((err) => {
          stopLoader('GetCards');
          console.log(err);
        });
      getMySubscriptions(state.data.customerId)
        .then(({ data }) => setSubsriptions(data))
        .catch((err) => console.log(err));
      GetDefaultPayment(state.data.customerId)
        .then(({ data }) => setDefaultPayment(data.data.defaultPaymentMethod))
        .catch((err) => console.log(err));
    }
  }, [refresh, state, detectChange]);

  const validateCardDeletion = (id) => {
    const mySubscription = LodashFind(subscriptions.data, { plan: { nickname: state.membershipStatus, active: true } });
    if (id === defaultPayment && mySubscription && !mySubscription.cancel_at_period_end) {
      return false;
    }
    return true;
  };

  const handleDeletePayment = (id) => {
    const validateDelete = validateCardDeletion(id);
    if (!validateDelete) return;
    startLoader('DeleteCard');
    SubmitWithToken({ paymentMethod: id }, API.DETACH_PAYMENT)
      .then(({ data }) => {
        stopLoader('DeleteCard');
        setRefresh(Math.random() * 100);
      })
      .catch((err) => {
        stopLoader('DeleteCard');
        console.log(err);
      });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <p className='title-text-small brown' style={{ margin: '2em auto' }}>
            Saved Cards
          </p>
        </div>
      </div>
      <div className={classes.InputContainer + ' ' + classes.CardContainer}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {cards.map((data, i) => {
            return (
              <div>
                <p style={{ position: 'relative', paddingLeft: '28px' }}>
                  {!profilePage ? (
                    <input
                      type='radio'
                      id={data.id}
                      name='radio-button'
                      value={data.id}
                      defaultChecked={i === 0}
                      onChange={(e) => (setPaymentMethod ? setPaymentMethod(e.target.value) : null)}
                    />
                  ) : null}
                  <label htmlFor={data.id}>
                    <div className={classes.CardUIContainer}>
                      <div>
                        <img className={classes.CardImage} src={CardBg} alt='' />
                      </div>
                      <div className={classes.CardParent}>
                        <div className={classes.CardTop}>
                          <div>{data.card.brand.toUpperCase()}</div>
                          <div className={classes.WithCross}>
                            <div className={classes.CVV}>
                              <div className={classes.UItitle}>Valid Thru</div>
                              <div className={classes.UIContent}>
                                {data.card.exp_month} / {data.card.exp_year}
                              </div>
                            </div>
                            {profilePage && validateCardDeletion(data.id) ? (
                              <div className={classes.CrossIcon} onClick={() => handleDeletePayment(data.id)}>
                                &#10005;
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className={classes.CardNum}>{`XXXX XXXX XXXX ${data.card.last4}`}</div>
                        <div className={classes.CardFooter}>
                          <div className={classes.CVV} style={{ color: 'white' }}>
                            {defaultPayment === data.id ? 'DEFAULT PAYMENT' : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </p>
              </div>
            );
          })}
          <div>
            <div style={{ cursor: 'pointer' }} className={classes.AddCard} onClick={() => setModalOpen(true)}>
              <div className={classes.AddCardText}>Add New Card</div>
            </div>
          </div>
        </div>
      </div>
      <AddCardModal modalOpen={modalOpen} setModalOpen={setModalOpen} setRefresh={setRefresh} />
    </div>
  );
};

export default ViewCards;
