import { SET_USER, REMOVE_USER } from '../../constants';

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      const { token, membershipStatus, data } = action.payload;
      if (token) localStorage.setItem('harvard_token', token);
      localStorage.setItem('harvard_membershipStatus', membershipStatus);
      localStorage.setItem('harvard_data', JSON.stringify(data));
      return {
        ...state,
        membershipStatus: membershipStatus,
        isAuthorized: true,
        data,
      };
    case REMOVE_USER:
      localStorage.clear();
      return {
        ...state,
        membershipStatus: null,
        isAuthorized: false,
        data: {},
      };
    default:
      return state;
  }
};
