import classes from './TransactionsTable.module.css';
import React, { useEffect, useState } from 'react';
import { GetTransactions } from '../../utils/FormSubmitHandler';
import { Capitalize } from '../../utils/stringHelper';
import Dropdown from 'react-dropdown';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const filters = [
  { label: 'All', value: '' },
  { label: 'Membership', value: 'membership' },
  { label: 'Event', value: 'event' },
  { label: 'Donation', value: 'donation' },
];
const today = new Date();

const TransactionsTable = ({ state }) => {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState(filters[0]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pagination, setPagination] = useState({});
  const [dates, setDates] = useState({
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: new Date(),
  });

  useEffect(() => {
    fetchData();
  }, [selectedPage]);

  useEffect(() => {
    fetchData(true);
  }, [filter]);

  const handleDateChange = () => {
    fetchData(true);
  };

  const fetchData = (initializePage) => {
    const formattedDates = {
      startDate: moment(dates.startDate).startOf('day').toISOString(),
      endDate: moment(dates.endDate).add(1, 'days').endOf('day').toISOString(),
    };
    const page = initializePage ? 0 : selectedPage;
    if (state.data.id)
      GetTransactions(page, filter.value, formattedDates, state.data.id)
        .then(({ data }) => {
          if (data) {
            setTransactions(data.data.data);
            const { pagination } = data.data;
            setPagination(pagination);
          }
        })
        .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className={classes.Filters}>
        <div className='filter-dropdown'>
          <Dropdown value={filters[0]} options={filters} onChange={(val) => setFilter(val)} placeholder='Select an option' />
        </div>
        <div style={{ display: 'flex', gridColumnGap: '1vw' }}>
          <div className={classes.Pickers}>
            <div>
              <label htmlFor='startDate'>From</label>
              <DatePicker id='startDate' selected={dates.startDate} onChange={(date) => setDates({ ...dates, startDate: date })} />
            </div>
            <div>
              <label htmlFor='endDate'>To</label>
              <DatePicker id='endDate' selected={dates.endDate} onChange={(date) => setDates({ ...dates, endDate: date })} />
            </div>
          </div>
          <div className='btn-primary filter-button' onClick={handleDateChange}>
            Filter
          </div>
        </div>
      </div>
      <div className={classes.TransactionsTable}>
        {transactions.length ? (
          <div>
            <table>
              <tr className={classes.TableHead}>
                <th>S.No</th>
                <th>Title</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
              {transactions.map((transaction, i) => (
                <tr>
                  <td>{pagination.offset + i + 1}</td>
                  <td>{Capitalize(transaction.type)}</td>
                  <td>${(parseFloat(transaction.amount) / 100).toFixed(2)}</td>
                  <td>{transaction.description || '-'}</td>
                  <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </table>
            {Math.ceil(pagination.totalCount / pagination.limit) > 1 ? (
              <ReactPaginate
                containerClassName='pagination-container'
                pageCount={Math.ceil(pagination.totalCount / pagination.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={({ selected }) => setSelectedPage(selected)}
                nextLabel='>'
                previousLabel='<'
              />
            ) : null}
          </div>
        ) : (
          <div>
            <p>No records found!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionsTable;
