import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import AnnouncementCard from '../../components/AnnouncementCard/AnnouncementCard';
import UpcomingEventsContainer from '../../components/UpcomingEvents/UpcomingEventsContainer';
import Welcome from '../../components/Welcome/Welcome';
import classes from './Home.module.css';
import { getAllEvents } from '../../utils/FormSubmitHandler';

const Home = () => {
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    const { data } = await getAllEvents();
    setEventData(data.data.data);
  };

  return (
    <div className={classes.Home}>
      <div>
        <Welcome events={eventData} />
      </div>
      <div className={classes.AnnouncementsContainer}>
        <AnnouncementCard />
      </div>
      <div className={classes.UpcomingEvents}>
        <UpcomingEventsContainer events={eventData} />
      </div>
    </div>
  );
};

export default Home;
