import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Buldge from '../../assets/Buldge.png';
import Logo from '../../assets/logo.png';
import ProfileLogo from '../../assets/profile.png';
import { HEADER_LINKS, PATHS, REMOVE_USER } from '../../constants';
import classes from './Header.module.css';
import { slide as Menu } from 'react-burger-menu';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import openNav from '../../assets/opennav.png';
import closeNav from '../../assets/closenav.png';
import { AuthContext } from '../../context/AuthContext';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';
import { toast } from 'react-toastify';

const Header = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const { state, dispatch } = useContext(AuthContext);

  const headerLinks = HEADER_LINKS.map((link) => (
    <NavLink exact to={link.path} activeClassName={classes.ActiveLink}>
      <div className={classes.Link} onClick={() => navClick()}>
        {link.name}
      </div>
    </NavLink>
  ));

  const navClick = () => {
    if (window.screen.width <= 600) document.querySelector('.bm-overlay').click();
  };

  const handleLogout = () => {
    dispatch({ type: REMOVE_USER });
    history.push('/');
    toast.success('Logout Success!');
  };

  useEffect(() => {
    const element = document.getElementById('root');
    if (open) disableBodyScroll(element);
    else enableBodyScroll(element);
  }, [open]);

  const handleChangePasswordInMobile = (val) => {
    if (window.screen.width <= 600) document.querySelector('.bm-overlay').click();
    setChangePasswordModal(true);
  };
  const handleLogoutInMobile = () => {
    if (window.screen.width <= 600) document.querySelector('.bm-overlay').click();
    dispatch({ type: REMOVE_USER });
    history.push('/');
    toast.success('Logout Success!');
  };
  return (
    <div className={classes.Header}>
      <div className={classes.Overlay}></div>
      <div className={classes.Logo}>
        <img src={Buldge} alt='' />
        <img src={Logo} alt='' />
      </div>
      <div className={classes.LinksContainer}>
        {headerLinks}
        <div className={classes.Link + ' ' + classes.ProfileContainer}>
          <img src={ProfileLogo} alt='' />
          <div className={classes.Profile}>
            {!state.isAuthorized ? (
              <>
                <NavLink to='/register'>Register</NavLink>
                <NavLink to='/auth/login'>Login</NavLink>{' '}
              </>
            ) : (
              <>
                <NavLink to='/profile'>Profile</NavLink>
                <div className={classes.ProfileLink} onClick={() => setChangePasswordModal(true)}>
                  Change Password
                </div>
                <div className={classes.ProfileLink} onClick={() => handleLogout()}>
                  Logout
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.HamMenu}>
        <Menu
          onStateChange={(e) => setOpen(e.isOpen)}
          customBurgerIcon={!open ? <img src={openNav} alt='' /> : <img src={closeNav} alt='' />}
          right
          width={'100%'}
        >
          {headerLinks}
          {state.isAuthorized ? (
            <>
              <NavLink to={PATHS.PROFILE}>
                <div className={classes.Link + ' bm-item'} onClick={() => navClick()}>
                  Profile
                </div>
              </NavLink>
              <div className={classes.Link + ' bm-item'} onClick={() => handleChangePasswordInMobile(true)}>
                Change Password
              </div>
              <div className={classes.Link + ' bm-item'} onClick={() => handleLogoutInMobile()}>
                Logout
              </div>
            </>
          ) : (
            <>
              <NavLink exact to={PATHS.REGISTER} activeClassName={classes.ActiveLink}>
                <div className={classes.Link + ' bm-item'} onClick={() => navClick()}>
                  Register
                </div>
              </NavLink>
              <NavLink exact to={PATHS.LOGIN}>
                <div className={classes.Link + ' bm-item'} onClick={() => navClick()}>
                  Login
                </div>
              </NavLink>
            </>
          )}
        </Menu>
      </div>
      <ChangePasswordModal open={changePasswordModal} setOpen={setChangePasswordModal} />
    </div>
  );
};

export default Header;
