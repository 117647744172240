import React from 'react';
import Modal from 'react-responsive-modal';
import AddCard from '../AddCard/AddCard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AddCardModal = ({ modalOpen, setModalOpen, setRefresh }) => {
  return (
    <Elements stripe={stripePromise}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddCard setRefresh={setRefresh} setModalOpen={setModalOpen} />
      </Modal>
    </Elements>
  );
};

export default AddCardModal;
