import React from 'react';
import classes from './Card.module.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Card = ({ item }) => {
  const history = useHistory();

  return item ? (
    <div className={classes.Card}>
      <div className={classes.ImageContainer}>
        <img src={item.homepageImage} alt='' />
        <div className={classes.ImageDate}>
          <div>{moment(item.startDate).format('MMM')}</div>
          <div className='bold'>{moment(item.startDate).date()}</div>
          <div>{moment(item.startDate).format('ddd')}</div>
        </div>
      </div>
      <div className={classes.CardTitle}>{item.title}</div>
      <div className={classes.CardFooter}>
        <div onClick={() => history.push('/events', { id: item.id })}>Know More</div>
        <div>
          Time:{' '}
          <span className='black'>
            {moment(item.startDate).format('h:mm a')} - {moment(item.endDate).format('h:mm a')}
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default Card;
