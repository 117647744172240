import React, { useState } from 'react';
import BgWithText from '../../components/BgWithText/BgWithText';
import classes from './Register.module.css';
import { ClassDropdown, DropdownComponent, Input, PhoneInput, TextArea } from '../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import { RegisterFormInitialValues, RegisterFormValidation } from '../../validations/RegisterFormValidation';
import { API, SignupHandler } from '../../utils/FormSubmitHandler';
import { AFFILIATION, CLASSES, PATHS, SUFFIX, TITLE, TOAST_ERR } from '../../constants';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const Register = () => {
  const [isDisabled, setDisabled] = useState(false);
  const history = useHistory();

  const loginClass = window.screen.width >= 600 ? ' btn-secondary' : ' btn-black';

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    SignupHandler(values, API.REGISTER)
      .then((res) => {
        toast.success(res.data.message);
        resetForm({});
        setDisabled(false);
        history.push(PATHS.LOGIN);
      })
      .catch((err) => {
        if (err && err.response) toast.error(err.response.data.data);
        else toast.error(TOAST_ERR);
        setDisabled(false);
      });
  };

  return (
    <div>
      <BgWithText title='register' image='bg2' />
      <div className={classes.SchoolContent}>
        <div className={classes.FormContainer}>
          <div className={classes.FormContent}>
            <div className='LargeText'>
              <span className='brown'> Register </span> Here
            </div>

            <div className='border'></div>
            <p className='title-text-small brown' style={{ margin: '2em auto' }}>
              Contact Information
            </p>
            {/* FORMSTART */}
            <Formik initialValues={RegisterFormInitialValues} validationSchema={RegisterFormValidation} onSubmit={handleSubmit}>
              {(props) => (
                <Form>
                  <div className={classes.InputContainer}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='firstName' label='First Name' required />
                      <Input name='middleName' label='Middle Name' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='lastName' label='Last Name' required />
                      <Input name='email' label='Email' required />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='lastNameAsStudent' label='Last Name As Student' />
                      <DropdownComponent
                        name='title'
                        label='Title'
                        values={TITLE}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <DropdownComponent
                        name='suffix'
                        label='Suffix'
                        values={SUFFIX}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                      />
                      <ClassDropdown
                        name='class'
                        label='class'
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        getFieldMeta={props.getFieldMeta}
                        values={CLASSES}
                        required
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <div>
                        <TextArea label='Address' name='addressLine1' />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                        }}
                      >
                        <DropdownComponent
                          name='affiliation'
                          label='affiliation'
                          values={AFFILIATION}
                          setFieldValue={props.setFieldValue}
                          setFieldTouched={props.setFieldTouched}
                          getFieldMeta={props.getFieldMeta}
                        />
                        <Input name='city' label='City' />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='state' label='State' />
                      <PhoneInput name='phone' label='Phone Number' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='zipcode' label='zipcode' />
                      <Input name='occupation' label='occupation' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='company' label='company' />
                      <PhoneInput name='homePhone' label='Home Phone' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <PhoneInput name='mobilePhone' label='Mobile Phone' />
                      <PhoneInput name='workPhone' label='Work Phone' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <PhoneInput name='fax' label='fax' />
                      <Input name='postEmail' label='Post Email' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='undergradDegreeAndYear' label='Undergraduate Degree and Year' />
                      <Input name='gradDegreeAndYear' label='Graduate Degree and Year' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='spouseName' label='Spouse/Partner Name' />
                      <Input name='spouseOccupation' label='His/Her Occupation/ Company' />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      className={classes.Inputs}
                    >
                      <Input name='hobbies' label='Hobbies and Interests' />
                      <Input name='nameBadge' label='Name Badge' />
                    </div>
                  </div>
                  <div className={classes.FooterTextContainer}>
                    <div className={classes.FooterText}>A temporary password will be e-mailed to you.</div>
                  </div>
                  <div className={classes.FooterContainer}>
                    <div className='flex'></div>
                    <div>
                      <button type='submit' disabled={isDisabled} className={classes.Submit + ' btn-primary'}>
                        Register
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {/* FORMEND */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
