import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Schools from "../pages/Schools/Schools";
import Home from "../pages/Home/Home";
import Volunteer from "../pages/Volunteer/Volunteer";
import Register from "../pages/Register/Register";
import AdvancedLeadership from "../pages/AdvancedLeadership/AdvancedLeadership";
import BookPrize from "../pages/BookPrize/BookPrize";
import AboutUs from "../pages/AboutUs/AboutUs";
import JoinUs from "../pages/JoinUs/JoinUs";
import Services from "../pages/Services/Services";
import Profile from "../pages/Profile/Profile";
import Events from "../pages/Events/Events";
import { AuthContext, AuthProvider } from "../context/AuthContext";
import MembershipTag from "../components/MembershipTag/MembershipTag";
import { VerifyUser } from "../utils/FormSubmitHandler";
import { REMOVE_USER, SET_USER } from "../constants";
import CancellationPolicy from "../pages/CancellationPolicy/CancellationPolicy";
import PrivacyPolicy from "../pages/CancellationPolicy/PrivacyPolicy";
import OverseesAndElections from "../pages/OverseesAndElections/OverseesAndElections";

const PublicLayout = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  });

  useEffect(() => {
    // verifyUserHandler();
    // eslint-disable-next-line
  }, []);

  const verifyUserHandler = () => {
    VerifyUser()
      .then(({ data }) => {
        const payload = {
          membershipStatus: data.data.membershipStatus,
          isAuthorized: true,
          data: data.data,
        };
        console.log(payload);
        dispatch({ type: SET_USER, payload });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({ type: REMOVE_USER });
        }
      });
  };

  return (
    <div>
      <AuthProvider />
      <div className="main-content">
        {state.isAuthorized && (
          <div className="membershipStatus">
            <MembershipTag status={state.membershipStatus} />
          </div>
        )}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/volunteer" component={Volunteer} />
          <Route path="/schools" component={Schools} />
          <Route path="/register" component={Register} />
          <Route path="/elections" component={OverseesAndElections} />
          {/* <Route path="/advancedLeadership" component={AdvancedLeadership} /> */}
          <Route path="/bookPrize" component={BookPrize} />
          <Route path="/aboutUs" component={AboutUs} />
          <Route path="/joinUs" component={JoinUs} />
          <Route path="/services" component={Services} />
          <Route path="/profile" component={Profile} />
          <Route path="/events" component={Events} />
          <Route path="/cancellationpolicy" component={CancellationPolicy} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
        </Switch>
      </div>
      <Footer />
      <AuthProvider />
    </div>
  );
};

export default PublicLayout;
